/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~animate.css/animate.min.css';
@import '~animate.css/animate.compat.css';

@import '~air-datepicker/air-datepicker.css';


//fix textarea scrolling issues
ion-textarea .native-textarea {
    overflow: auto !important;
 }
 

//IONIC 6 Fixes
// ion-select-popover {
//     min-width: 250px;
// }

.sc-ion-alert-ios-h {
    --max-width: 600px;
}
.sc-ion-alert-ios-h {
    --min-width: 250px;
    --width: 30%;
}

body {
    color: #333;
}
//END IONIC 6 Fixes

.patient-profile {
    position: absolute;
    z-index: 99;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
}


.patient-functions {
    position: absolute;
    z-index: 100;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
}


//START ANGULAR CALENDAR
/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";


    .cal-week-view .cal-header.cal-today { //today marker on header
        background-color: #76cdf8;
    }

    .cal-week-view .cal-day-column { //column divicers
        border-left: 1px solid #c2c2c2;
        //border-right: 1px solid #333;
        margin-right: 3px;
    }


    .cal-month-view .cal-day-number {
        font-weight: bold;
    }
    //weekend color
    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
        color: rgb(0, 21, 61) !important;
        font-weight: normal;
    }



    .cal-day-selected, .cal-day-selected:hover  { //current day marker
        background-color: #3e8ed4 !important; 
        color: #fff !important;
        border-radius: 15px;
    }
    .cal-day-cell.cal-today { //today marker in skip calendar
        border: 2px solid #76cdf8 !important;
        border-radius: 15px;
    }

    
    .largeCal {
        height: 100%;
    }

    .cal-deactivated {
        background-color: #550f6836;
    }

    .cal-available {
        background-color: #50e08e31 !important;
        border-left: 3px solid #00b64f;
        //background-color: inherit !important;
    }

    .cal-week-view .cal-time-events .cal-event {
        margin-left: 4px; //add left gap to events
    }

    //CUSTOM EVENT BUTTONS 
    .cal-current-time-marker {
        height: 3px;
        background: linear-gradient(270deg, #ec268d, #1f55dd);
        background-size: 400% 400%;
        animation: background-fade 5s ease infinite;
      
        @keyframes background-fade {
          0% {
            background-position: 0 50%;
          }
      
          50% {
            background-position: 100% 50%;
          }
      
          100% {
            background-position: 0 50%;
          }
        }
    }

    .cal-week-view .cal-time-events .cal-event-container {
        max-width: 90% !important;
    }

    .cal-event {
        border-left-width: 10px !important;
    }
    mwl-calendar-event-title > .ng-star-inserted {
        height: 100%;
    }
    //week view event margin
    // .cal-week-view .cal-time-events .cal-day-column {
    //     margin-left: 10px;
    // }

    // .cal-week-view .cal-hour {
    //     width: calc(100% + 10px);
    // }
    //end 


    .cal-week-view .cal-event { //text color for events
        color: #222 !important;
    }

    .cal-edit {
        padding: 2px 10px;
        display: inline-block;
        border-radius: 30px;
        background: #333;
    }

    .cal-delete {
        padding: 2px 10px;
        display: inline-block;
        border-radius: 30px;
        background: rgb(176, 1, 1);
    }

    //CALENDAR SIDEBAR STYLING
    .cal-month-view .cal-header .cal-cell {
        font-size: 0;
        color: #777;
    }
    
    .cal-month-view .cal-header .cal-cell:first-letter {
        font-size: 12px;
    }


    .cal-month-view .cal-day-cell.cal-today .cal-day-number {
        font-size: 1.2em !important;
    }

    .cal-month-view .cal-day-cell {
        min-height: initial !important;
    }

    .cal-month-view .cal-cell-top {
        min-height: initial !important;
        text-align: center;
        cursor: pointer;
        padding-bottom: 20px;
    }
    

    .cal-month-view .cal-day-cell {
        position: relative;
    }

    .cal-month-view .cal-day-badge { //# of events badge
        margin-top: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        bottom: 2px;
        background-color: #0f87c1;
    }

    .cal-month-view .cal-events { //remove dots that indicate events
        display: none;
    }

    .cal-month-view .cal-day-number {
        margin-top: 0px;
        margin-right: 0px;
        float: none;
        margin-bottom: 0 !important;
    }

    .cal-month-view .cal-day-cell:not(:last-child) { //remove right table borders
        border-right: none;
    }
    .cal-month-view .cal-days .cal-cell-row { //remove bottom table borders
        //border-bottom: none;
    }

    //WEEK VIEW FORMAT
    .cal-week-view .cal-day-headers { //sticky header
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: #efefef;
        padding-left: 0px !important;
        border-left: 70px solid #797979;
        border-bottom: 1px solid #797979;
        //color: #fff !important;
    }
    .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left-color: #4f4f4f;
    }
    .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right-color: #4f4f4f;
    }
    
    .cal-week-view .cal-time-label-column { //sticky time column (even)
        position: sticky;
        left: 0;
        z-index: 2;
        overflow: hidden;
        //border-bottom: 1px solid #666 !important;
    }

    .cal-time-label-column .cal-hour-segment {
        border-bottom: 1px solid #292929 !important;
        //background: #2c2c2c;
        color: #fff !important;
    }

    
    .cal-week-view .cal-time-label-column {
        background: #333 !important;
    }

    .cal-week-view .cal-time-label-column .cal-hour-odd { //sticky time column (odd)
        background: #3f3f3f !important;
    }

    .cal-week-view .cal-hour {
        width: 100% !important;
    }

    

    //EVENT FORMAT
    .cal-week-view .cal-ends-within-day .cal-event { //calendar event let words flow
        white-space: inherit;
    }

    .cal-event-container.cal-drag-active { //MOVE AN EVENT - MAKES IT TRANSPARENT AND FIXES THE OFFSET CALCULATIONS
        z-index: 4 !important;
        opacity: 0.5;
        margin-left: -30%;
    }

//END ANGULAR CALENDAR


.grid-25 {
    width: 23%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
    display: inline-flex;
    position: relative;
}

.grid-33 {
    width: 30%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
    display: inline-flex;
    position: relative;
    vertical-align: top;
}

.grid-50 {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
    display: inline-flex;
    position: relative;
}

.no-bullet {
    list-style: none;
}

.spaced-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.spaced-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.spaced-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.native-textarea {
    padding: 10px !important;
}

#iconSidebar {
    max-width: 60px;
    line-height: 50px;
    min-width: 0px;
    background-color: #1f1f1f;
    --ion-background-color: #1f1f1f;
    flex-shrink: unset;
    //border-right: 1px solid #efefef;
}

#iconSidebar ion-button ion-icon {
    font-size: 30px;
}

#iconSidebar ion-button {
    min-height: 50px;
    margin-bottom: 10px;
}



//UPLOAD IMAGE

.upload-btn-img {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-img:hover {
    background: #efefef;
}

.upload-btn-img-btn {
    background-color: transparent !important;
}


.upload-btn-img input[type=file] {
    font-size: 100px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
//END UPLOAD IMAGE


.billing-entry { background-color: #d2efb5; border-left: 5px solid #a8c766 }
.invoice-entry { background-color: #b5efbc; border-left: 5px solid #66c771 }
.exam-entry { background-color: #f8b8e9; border-left: 5px solid #bb3d9e }
.form-entry { background-color: #c69eea; border-left: 5px solid #803dbb }
.attachment-entry {background-color: #a4cbe9; border-left: 5px solid #3d84bb}
.prescription-entry {background-color: #1cc2b7; border-left: 5px solid #1cc2b7}
.coverage-entry {background-color: #95f58b; border-left: 5px solid #36d324}

.status-0, .status-1, .status-2, .status-3, .status-4, .billing-entry, .invoice-entry, .exam-entry, .form-entry, .attachment-entry, .prescription-entry, .coverage-entry {
    //border-radius: 10px;
    margin-left: 15px;
}

.markinput {
    background-color: #efefef;
    margin-top: 7px;
}

.autofill {
    background-color: #efefef;
    border: 0px;
    padding: 3px 5px 7px;
    width: 100%;
}

.infoSelect {
    -webkit-appearance: none;
    margin: 0px;
    background-color: none !important;
    background: none !important;
    border: 0px;
    padding: 3px 5px 7px;
}

.inputlist {
    //background: transparent;
    border: 0px;
    padding: 3px 5px 7px;
    margin-top: 0px;
}

.inputlist-stacked {
    //background: transparent;
    border: 0px;
    padding: 3px 5px 7px;
    margin-top: 10px;
}

textarea {
    background-color: #efefef !important;
    color: #222 !important;
}




// CUSTOM THEME ENTRIES  INPUT fieldset
.ion-stacked .label-stacked {
    min-height: 45px;
    font-size: 20px !important;
    font-weight: bold;
    background: #f5f7f8;
    border-radius: 20px 20px 0px 0px;
    padding: 5px 0px 5px 10px;
    margin-bottom: 7px;
}

.ion-stacked .label-md {
    color: #555;
    font-size: 14px;
    
}

.hover:hover {
    background: #efefef;
}

.template-2, .template-3 {
    border-left: none !important;
    border: none !important;
    box-shadow: none !important;
}

ion-list-header {
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

ion-list-header ion-button {
    background: #b3d8d9 !important;
    border-radius: 20px;
    margin-right: 10px;
}

.native-textarea {
    padding: 10px !important;
}

ion-segment {
    border-bottom: 1px solid #dcdcdc !important;
    margin-bottom: 10px;
}
.segment-button-checked {
    background-color: #e0eff7 !important;
    border-radius: 20px 20px 0px 0px !important;
    color: #000;
}


.ion-input ion-label:first-child {
    //border-right: 2px solid #b3d8d9;
    margin-right: 5px;
    font-weight: bold;
    background-color: #f5f6f8;
    border-radius: 10px 0px 0px 10px;
    padding: 5px 0px 0px 10px;
    height: 30px;
    min-width: 145px !important;
}

.ion-input .label-md {
    color: #555;
    font-size: 14px;
    font-weight: 600;
}

.ion-input .native-input {
    background-color: #f4f4f4;
    --background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    // --placeholder-font-weight: 300;
    // font-weight: 400;
}

.ion-input ion-select {
    background-color: #f4f4f4;
    background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin: 0px;
}

.ion-input .input {
    background-color: #f4f4f4;
    background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin: 0px;
}


.ion-stacked-label ion-label {
    font-weight: bold;
    font-size: 20px !important;
    //color: #184694 !important;
}


.ion-stacked {
    border: 1px solid #fff;
}

.ion-stacked ion-label {
    min-height: 35px;
}

.ion-stacked .label-md {
    color: #555;
    font-size: 14px;
    font-weight: 600;
}

.ion-stacked .native-input {
    background-color: #f4f4f4;
    --background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    // --placeholder-font-weight: 300;
    // font-weight: 400;
}

.ion-stacked ion-select {
    background-color: #f4f4f4;
    background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin: 0px;
}

.item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
    border-bottom-color: rgb(222, 222, 222);
    /*border-bottom-color: transparent;*/
    /*border-bottom: none;*/
    box-shadow: none;
}
// END CUSTOM THEME ENTRIES INPUT FIELDS










.bg-white {
    background-color: #fff;
}

.select-viewer {
    background-color: transparent;
    color: #333;
    font-weight: bold;
    border: 0px;

    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* CALENDAR OVERRIDES */
ion-calendar {
    background-color: transparent !important;
}
.month-packer-item button {
    color: #333 !important;
}


/* GLOBAL OVERRIDES */


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }
  
  .upload-btn {
    width: 100%;
    height: 200px;
    border: 1px solid rgb(194, 194, 194);
    color: gray;
    background-color: #e3eeff;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }


.picker-col {
    overflow: auto;
    min-width: 60px;
}

.picker-col::-webkit-scrollbar {
    //width: 0px; /* width of the entire scrollbar */
}


.hidden {
    visibility: hidden;
}
.noshow {
    display: none;
}
.noshow-absolute {
    display: none;
    position: absolute;
    z-index: -1;
}


/* TIMELINE */
/*Timeline element*/
.timeline-item {
    border-left: 5px solid #fff;
}

.timeline-item-selected {
    border-left: 5px solid #007cdc;
    --background: #dcdcdc;
    background: #dcdcdc;
    //background-color: #007cdc;
    //color: #fff;
}
/*End timeline element*/



.patientSearch {
    position: absolute;
    left: 100%;
    width: 100%;
    top: 0;
    z-index: 5;
}


//PRINT VARIABLES

.printable-content {
    background-color: #dcdcdc;
    overflow: auto;
}

.printable-wrapper {
    transform: scale(0.7);
    width: 10in;
    transform-origin: 0 50px;
}

.printable-area {
    background-color: #fff;
    margin: 0 auto;
}

.page-break {
    page-break-after: always;
    page-break-inside: avoid;
    clear: both;
}

.page-break-before {
    page-break-before: always;
    page-break-inside: avoid;
    clear: both;
}

#html-2-pdfwrapper2 {
    position: absolute;
    left: 20px;
    top: 350px;
    bottom: 0;
    overflow: auto;
    width: 600px;
}
//END PRINT VARIABLES



/* GLOBAL OVERRIDES */

.txt-avatar {
    background-color: #538fcd;
    display: table;
}

.txt-avatar-content {
    display: table-cell;
    text-transform: uppercase;
    text-align: center;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

ion-item:hover {
    --background: #dcdcdc;
    background: #dcdcdc;
    color: #000;
}
ion-item.nohover:hover {
    --background: none;
    background: none;
}

.pad5 {
    padding: 5px;
}

.pad10 {
    padding: 10px;
}

.pad15 {
    padding: 15px;
}

.pad20 {
    padding: 20px;
}

.sm-show {
    display: none;
}


.hide {
    display: none;
}

/*.sc-ion-segment-md-h {
    --color-checked: #fff;
}*/

/*Override segment lower border*/
#receiptPreview {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
    display: none;
}

#receiptPreview .receiptContent {
    top: 2.5%;
    left: 10%;
    right: 10%;
    bottom: 3%;
    position: absolute;
    z-index: 101;
    background-color: #fff;
    padding: 20px;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
}



hr {
    --ion-background-color: #dcdcdc;
    background-color: #dcdcdc;
}

#iconSidebar {
    max-width: 60px;
    line-height: 50px;
    min-width: 0px;
    background-color: #000;
    --ion-background-color: #0000;
    flex-shrink: unset;
    border-right: 1px solid #efefef;
}

#iconSidebar ion-button ion-icon {
    font-size: 30px;
}

#iconSidebar ion-button {
    min-height: 50px;
    margin-bottom: 10px;
}




.scroll-content {
    overflow: auto;
}

.selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.code {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    background: #dcdcdc;
    --background: #dcdcdc;
    font-size: 10px;
    font-family: Courier New, Courier, monospace;
}

.resp {
    width: 100%;
    height: auto;
}

.vresp {
    height: 100%;
    width: auto;
}

.vs {
    height: 100%;
    overflow: auto;
    padding-right: 5px;
}



.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }

.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }

.mb-0 { margin-bottom: 0px; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }

.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }

.pt-0 {padding-top: 0;}
.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px; }


.pb-0 {padding-bottom: 0px; }

.mw-300 {
    max-width: 300px;
}

.mw-30 {
    max-width: 30%;
}
.mw-33 {
    max-width: 33%;
}
.mw-40 {
    max-width: 40%;
}

.w100 {
    width: 100%;
}

.w75 {
    width: 75%;
}

.w66 {
    width: 66%;
}

.w50 {
    width: 50%;
}
.w40 {
    width: 40%;
}

.w33 {
    width: 33%;
}
.w30 {
    width: 30%;
}

.w25 {
    width: 25%;
}

.w20 {
    width: 20%;
}

.w10 {
    width: 10%;
}

.h100 {
    height: 100%;
}
.r5 {
    border-radius: 5px;
}

.r10 {
    border-radius: 10px;
}

.r20 {
    border-radius: 20px;
}

.r30 {
    border-radius: 30px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}
.p30 {
    padding: 20px;
}

.m5 {
    margin: 5px;
}
.m0 {
    margin: 0px;
}

.m10 {margin: 10px;}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.h100-segment {
    height: calc(100% - 55px);
}

.sm-h100-segment {
    height: 100%;
}

.section-fh-hidden {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.section-fh {
    overflow: auto;
    position: relative;
    height: 100%;
}

.section-fh-border {
    overflow: auto;
    position: relative;
    border-right: 1px solid #efefef;
    height: 100%;
}

.section-fh-border-visible {
    position: relative;
    border-right: 1px solid #efefef;
    height: 100%;
    overflow: visible;
}

.companion-border {
    border-left: 1px solid #efefef;
}

.collapse-companion {
    height: 100%;
    overflow: auto;
}

.lg-avatar {
    width: 125px;
    height: 125px;
    font-size: 45px;
    margin-bottom: 10px;
}
.el-center {
    margin: 0 auto;
}
.img-center {
    display: block;
    margin: 0 auto;
}

.btn-nomargin {
    margin-right: 0px;
    margin: 0px;
}

.select-right {
    text-align-last: right; 
}

.txt-uppercase {
    text-transform: uppercase;
}

.txt-lowercase {
    text-transform: lowercase;
}

.txt-camelcase:first-letter {
    text-transform: uppercase;
}

.txt-left {
    text-align: left;
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.txt-dark {
    color: #000;
}

.txt-primary {
    color: #007cdc;
}

.txt-danger {
    color: #eb445a;
}

.txt-tertiary {
    color: #6a64ff;
}

.txt-success {
    color: #28a745;
}

.txt-warning {
    color: #e6b219;
}

.txt-white {
    color: #ffffff;
}


.txt-grey {
    color: #555555;
}

.txt-strike {
    text-decoration: line-through;
}

.txt-large {
    font-size: 25px;
}
.txt-medium {
    font-size: 22px;
}
.txt-small {
    font-size: 12px;
}

.txt-bold {
    font-weight: bold;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-border {
    border: none;
}
.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}




.drop-cap-med {
    font-size: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    line-height: 60px;
    background-color: #333;
    color: #fff;
    text-align: center;
}

.drop-cap-large {
    font-size: 30px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 80px;
    background-color: #333;
    color: #fff;
    text-align: center;
}

.panel {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
    background-color: #efefef
}

.panel-spaced {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 40px;
}

.panel-dashed {
    border: 1px dashed #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
}

.panel-primary {
    background-color: #337ab7;
    color: #fff;
    padding: 5px;
}

.panel-light {
    border: 1px solid #d0d0d0;
    background-color: #fff;
    padding: 15px;
}

.panel-float-bottom {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #333;
    color: #fff;
}

.panel-fh-overlay-hide {
    position: absolute;
    background-color: #fff;
    z-index: 15;
    display: none;
    height: 100%;
    border-right: 2px solid #333;
}

.panel-form-row-top {
    background-color: #666;
}

.panel-form-row {
    background-color: #efefef;
    padding: 20px;
    border: 1px dashed #666;
    border-top: none;
}

.panel-form-col-top {
    background-color: #dcdcdc;
    border: 1px solid #666;
    border-bottom: none;
}

.panel-form-col {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #666;
    border-top: none;
}

.panel-form-obj-top {
    background-color: #fff;
    border: 1px solid #999;
    border-bottom: none;
}

.panel-form-obj {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #999;
    border-top: none;
}

.screen-shade {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#lockScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#lockScreen .lockContent {
    width: 500px;
    margin: 0 auto;
    margin-top: 50px;
    z-index: 110;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}



.closeModal {
    border-left: 1px solid #555;
    border-bottom: 1px solid #555;
    font-size: 18px;
    font-weight: bold;
    width: 60px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 120;
    border-radius: 0 0px 0 30px;
    text-align: center;
    line-height: 50px;
    background-color: #555;
    color: #fff;
    cursor: pointer;
}

.closeModal:hover {
    background-color: #333;
}

#modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#modal .modalContent {
    top: 2.5%;
    left: 5%;
    right: 5%;
    bottom: 3%;
    position: absolute;
    z-index: 101;
    padding: 20px;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    color: #000;
    background: #fff;
}



#miniModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#miniModal .modalContent {
    top: 25%;
    left: 20%;
    right: 20%;
    bottom: 25%;
    position: absolute;
    z-index: 110;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    background: #fff;
}

#microModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 125;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#microModal .modalContent {
    top: 30%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    position: absolute;
    z-index: 130;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    background: #fff;
}

/*pin pad*/
.pinPad {
    width: 100%;
}

.pinPad td {
    width: 33%;
    border: 1px solid #b8b8b8;
    background-color: #fdfdfd;
}

.pinPad button {
    width: 100%;
    height: 80px;
    text-align: center;
    vertical-align: middle;
    color: #000;
    font-size: 30px;
}

.pinPad button:active {
    background-color: #c0c3c7;
}

.pinInput {
    width: 100%;
    border: none;
    height: 50px;
    font-size: 30px;
    text-align: center;
    background-color: #efefef;
}


.bubble {
    background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: inline-block;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
}

.bubble::before {
    background-color: #F2F2F2;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform: rotate( 29deg ) skew( -35deg );
    -moz-transform: rotate( 29deg ) skew( -35deg );
    -ms-transform: rotate( 29deg ) skew( -35deg );
    -o-transform: rotate( 29deg ) skew( -35deg );
    -webkit-transform: rotate( 29deg ) skew( -35deg );
    width: 20px;
}


.b-right {
    float: right;
    margin: 5px 20px 5px 45px;
    background-color: #DCF8C6;
}

.b-right::before {
    box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    right: -9px;
    background-color: #DCF8C6;
}

.b-left {
    float: left;
    margin: 5px 45px 5px 20px;
}

.b-left::before {
    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    left: -9px;
}

.b-bottom {
    float: left;
    margin: 0px 45px 5px 0px;
}

.b-bottom::before {
    box-shadow: 2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    top: calc(100% - 9px);
}

.b-top {
    float: left;
    margin: 0px 45px 5px 0px;
}

.b-top::before {
    box-shadow: -2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    top: -9px;
}

.b-top-right {
    float: right;
    margin: 0px 15px 5px 0px;
}

.b-top-right::before {
    box-shadow: -2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    top: -7px;
    right: 20px;
}


.tbl th {
    padding: 8px;
    font-size: 16px;
}

.tbl td {
    padding: 8px 5px 8px 5px;
    font-size: 16px;
}

.tbl tbody tr {
    border-top: 1px solid #dcdcdc;
}

.tbl tfoot tr {
    border-top: 1px solid #dcdcdc;
    font-weight: bold;
}

.tbl-striped td{
    padding: 15px;
}

.tbl-striped tr:nth-child(even) {
    background-color: #efefef;
}


.searchbar-md .searchbar-input {
    background-color: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: inherit;
    line-height: inherit;
}

.searchbar-md .searchbar-search-icon {
    display: none;
}


.lg-collapse-companion {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 3;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
}


//APPOINTMENT SELECTOR
.appt-selector {
    border-right: 5px solid transparent;
    position: relative;
    border-top: 2px solid transparent;
}

.appt-selector:hover {
    background-color: #dcdcdc;
    --background-color: #dcdcdc;
}

.appt-selector-footer {background: #fff;}

.calendar-table tr:nth-child(2n+1) {
    background-color: #f8f8f8;
}


.calendar-table tr:nth-child(4n+1) {
    background-color: #f2f2f2;
}
//END APPOINTMETN SELECTOR




//POINT OF SALE TERMINAL STYLING
.menuItem {
    display: inline-block;
    margin: 2.5% 2.5%;
    width: 45%;
    height: 100px;
    white-space: normal;
    word-wrap: break-word;
}

.btn-product {
    white-space: normal;
    word-wrap: break-word;
}


.orderItem {
    width: 100%;
    border: 1px solid #efefef;
    padding: 8px;
    margin-top: 5px;
}

.orderItem img {
    height: 70px;
    width: auto;
}

.orderItem > .lenseOptions {
    background-color: #efefef;
    width: 100%;
    padding: 8px;
}

.description {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    width: fit-content;
    white-space: nowrap;
}

@keyframes colorIn {
    from {
        background-color: #337ab7;
    }

    to {
        background-color: #fff;
    }
}

@keyframes colorInDARK {
    from {
        background-color: #337ab7;
    }

    to {
        background-color: #0000;
    }
}


.colorIn {
    -webkit-animation-name: colorIn;
    animation-name: colorIn;
}
//END POS STYLING























//START THEME ADJUSTMENTS
.dark-hide { display: block; }
.dark-show { display: none; }
.light-hide { display: none; }
.light-show { display: block; }

//START DARK THEME DARK
//@media (prefers-color-scheme: dark) {
body.dark {



    //CALENDAR DARK
    .cal-month-view .cal-days .cal-cell-row {
        background-color: #111 !important;
        --background-color: #111 !important;
        background: #111 !important;
        --background: #111 !important;
      }


      .cal-week-view mwl-calendar-week-view-hour-segment, .cal-week-view .cal-hour-segment {
        background-color: #111 !important;
        --background-color: #111 !important;
        background: #111 !important;
        --background: #111 !important;
      }
    //END CALENDAR DARK


    .patient-profile {
        background: #000;
    }
    .patient-functions {
        background: #000;
    }

    .markinput {
        background-color: #161616;
    }




    //POINT OF SALE TERMINAL STYLING
    .orderItem {
        border: 1px solid #1a1a1a;
    }


    .orderItem > .lenseOptions {
        background-color: #1e1e1e;
    }

    .colorIn {
        -webkit-animation-name: colorInDark;
        animation-name: colorInDark;
    }
    //END POS STYLING




    //APPOINTMENT SELECTOR

    .appt-selector:hover {
        background-color: #2e2e2e;
        --background-color: #373737;
    }

    .appt-selector-footer {background: #000;}

    
    .calendar-table tr:nth-child(2n+1) {
        background-color: #1d1d1d !important;
    }
    
    
    .calendar-table tr:nth-child(4n+1) {
        background-color: #1f1f1f !important;
    }
    //edn //APPOINTMENT SELECTOR

    .upload-btn {
        background-color: #3b3e43;
      }
      
    .dark-hide {
        display: none;
    }

    .dark-show {
        display: block;
    }

    .light-hide {
        display: block;
    }

    .light-show {
        display: none;
    }

    .txt-white {
        color: #fff;
    }
    

    #modal .modalContent {
        color: #fff;
        background: #000;
    }

    #miniModal .modalContent {
        color: #fff;
        background: #000;

    }

    .bubble {
        background-color: #222;
        box-shadow: 0 0 6px #B2B2B2;
    }

    .bubble::before {
        background-color: #222;
    }




    .panel {
        border: 1px solid #888;
        background-color: #222;
        padding: 8px;
        margin-bottom: 10px;
    }

    .panel-border {
        border: 1px solid #888;
    }


    .panel-spaced {
        border: 1px solid #444;
    }

    .section-fh-border {
        border-right: 1px solid #333;
    }


    .logo {
        -webkit-filter: invert(1) hue-rotate(180deg);
        filter: invert(1) hue-rotate(180deg);
    }

    .dark-invert {
        -webkit-filter: invert(1) hue-rotate(180deg);
        filter: invert(1) hue-rotate(180deg);
    }

    ion-item:hover {
        --background: #333;
        background: #333;
        color: #fff;
    }

    .ion-input ion-label {
        border-right: 3px double #007cdc;
        margin-right: 5px;
    }


    .ion-input .native-input {
        background-color: #444;
        --background: #444;
        border: 1px solid #888;
        padding: 5px 10px 5px 10px;
    }

    .ion-input ion-select {
        --background-color: #444;
        background-color: #444;
        --background: #444;
        background: #444;
        border: 1px solid #888;
    }


    .profile-members-container {
        background-color: #444444;
    }


    .profile-members:hover {
        background-color: #666666;
    }



    //SIDEBAR ADJSUTMENTS
    #iconSidebar {
        background-color: #e8e8e8;
        --ion-background-color: #e8e8e8;
    }


    
    //DARK CUSTOM THEME ENTRIES  INPUT fieldset
    .ion-stacked .label-stacked {
        background: #1a1a1a;
    }
    
    .ion-stacked .label-md {
        color: #555;
    }
    
    .hover:hover {
        background: #1a1a1a;
    }

    textarea {
        background-color: #292929 !important;
        color: #e0e0e0 !important;
    }

    ion-list-header {
        border-radius: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    ion-list-header ion-button {
        background: #399497 !important;
        border-radius: 20px;
        margin-right: 10px;
    }

    ion-segment {
        border-bottom: 1px solid #414141 !important;
    }
    .segment-button-checked {
        background-color: #303335 !important;
        color: #ffffff;
    }
    
    
    .ion-input ion-label {
        background-color: #151516;
    }
    
    .ion-input .native-input {
        background-color: #1a1a1a;
        --background: #1a1a1a;
        border: 1px solid #292929;
    }
    
    .ion-input ion-select {
        background-color: #1a1a1a;
        background: #1a1a1a;
        border: 1px solid #292929;
    }
    
    .ion-input .input {
        background-color: #1a1a1a;
        background: #1a1a1a;
        border: 1px solid #292929;
    }
    
    .ion-stacked {
        border: 1px solid #000;
    }
    
    .ion-stacked .native-input {
        background-color: #1a1a1a;
        --background: #1a1a1a;
        border: 1px solid #292929;
    }
    
    .ion-stacked ion-select {
        background-color: #1a1a1a;
        background: #1a1a1a;
        border: 1px solid #292929;
    }
    
    .item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
    .item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
    .list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child,
    .list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
        border-bottom-color: rgb(64, 64, 64);
    }
    //END CUSTOM THEME ENTRIES  INPUT fieldset


    ion-item.nohover:hover {
        --background: none;
        background: none;
    }
    
}
//}
//END DARK THEME DARK 





















/*START ANIMATIONS*/
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated-fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
/*END ANIMATIONS*/


.btn-selected-white {
    background-color: #007cdc;
    color: #fff;
    --ion-color-base: #fff;
}


/*start dark segment*/
.segment-container {
    background-color: #222;
    margin-top: 10px;
    margin-bottom: 10px;
}

.segment-container-button {
    width: 50%;
    margin: 0px;
}

.selected {
    --background: #007cdc !important;
    background: #007cdc !important;
    color: #fff;
}

.selected ion-note {
    color: #efefef;
}

.selected:hover {
    --background: #0061af;
    background: #0061af;
    color: #fff;
}

.selected-round {
    --background: #007cdc;
    background: #007cdc;
    color: #fff;
    border-radius: 20px;
}

.selected-round:hover {
    --background: #0061af;
    background: #0061af;
    color: #fff;
}

.selected-white {
    background-color: #007cdc;
    --background-color: #007cdc;
    color: #fff;
    --ion-color-base: #fff;
}

.selected-white:hover {
    background-color: #0061af;
    --background-color: #0061af;
    color: #fff;
    --ion-color-base: #fff;
}

.selected-border {
    border-bottom: 3px solid #007cdc;
}

.selected-border-light {
    border-bottom: 3px solid #dcdcdc;
}

.disabled {
    opacity: 0.4;
}

.disabled:hover {
    opacity: 0.5;
}

.segment-button:hover {
    background-color: #dcdcdc;
}


.es-segment {
    background-color: #000;
    color: #fff;
    overflow-x: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 0 10px 0 10px;
}


.dark-pill {
    background-color: #000;
    color: #fff;
    border-radius: 30px;
    min-width: 50px;
    max-width: 150px;
}

/*.dark-pill:hover {
    background-color: #444;
}

.dark-segment {
    background-color: #000;
    color: #fff;
    overflow-x: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 0 10px 0 10px;
}

.dark-segment ion-segment-button:hover {
    background-color: #444;
}

.dark-segment ion-segment-button.segment-button-checked:hover {
    background-color: #fff;
}*/

.segment-fix {
    justify-content: flex-start;
    width: 100%;
    text-align: center;
}

/*.dark-segment .segment-button-layout-icon-top {
    color: #fff;
}

.dark-segment .segment-button-checked {
    color: #000;
    background-color: #fff;
    --background-color: #fff;
    border: 2px solid #000;
    border-bottom: 2px solid #fff;
    border-radius: 5px 5px 0 0;
}*/


.light-segment {
    background-color: #888;
    color: #000;
    border-bottom: 2px solid #fff;
    overflow-x: auto;
}

.light-segment .segment-button-layout-icon-top {
    color: #fff;
}

.light-segment .segment-button-checked {
    color: #000;
    background-color: #fff;
    --background-color: #fff;
    border: 2px solid #dcdcdc;
    border-bottom: 2px solid #fff;
    border-radius: 5px 5px 0 0;
}

/*end dark segment*/


/*Profile elements*/
.profile {
    position: relative;
    background-color: #333;
    min-height: 150px;
}

.profile .edit {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: rgba(0,0,0,0.3);
}

.profile .sysinfo {
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: #fff;
    width: 100%;
    font-weight: bold;
}


.profile-members {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding: 2px;
}


.profile-members:hover {
    background-color: #dcdcdc;
    color: #fff;
    cursor: pointer;
}

.profile-members-container {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #efefef;
}


.profile-members-selected {
    background-color: #fff;
    color: #000;
}

.profile-members-hide {
    display: none;
}

/*table Element*/

.tbl-pinpad > thead {
    border-bottom: 20px solid #fff;
}

.tbl-pinpad > tfoot {
    border-top: 20px solid #fff;
}

.tbl-striped tr:nth-child(even) {
    background: #efefef;
}

.tbl-striped thead {
    //color: #fff;
    //background: #dcdcdc;
}
/* End table element */



/* Overrides */
ion-select {
    max-width: 100%;
    margin: 13px 8px;
}
/* End Overrides */


/*
xs 0 size-
Set columns when (min-width: 0)

sm 576px size-sm-
Set columns when (min-width: 576px)


md 768px size-md-
Set columns when (min-width: 768px)

lg 992px size-lg-
Set columns when (min-width: 992px)

xl 1200px size-xl-
Set columns when (min-width: 1200px)
*/


/* LG SCREEN OVERRIDES */
@media (min-width: 992px) {
    /*make ion-input labels wider*/
    .label-fixed.sc-ion-label-md-h {
        min-width: 140px;
        max-width: 300px;
    }

    /*make confirmation and alert boxes wider*/
    .alert-wrapper.sc-ion-alert-md {
        min-width: 400px;
        max-width: 600px;
    }

    .lg-hide {
        display: none;
    }

    .lg-show {
        display: block;
    }

    .header {
        display: none;
    }

    .scroll-content {
        margin-top: 0px;
    }
}

/* MID & SMALL SCREEN OVERRIDES */
@media (max-width: 991px) {
    .lg-show {
        display: none;
    }

    .lg-hide {
        display: block;
    }
}


@media screen and (max-width: 576px) {
    /*if mobile screen*/
    .sm-show {
        display: block;
    }

    .sm-hide {
        display: none;
    }

    .sm-h100-segment {
        height: calc(100% - 55px);
    }

    .section-fh-border {
        border-right: 0px;
    }

    body.dark {
        .section-fh-border {
            border-right: 0px;
        }
    }

    .patientSearch {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        z-index: 5;
    }

    .col-sm-hide {
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 10;
        background: #fff;
        display: none;
    }

    table .collapse > td {
        width: 100%;
        display: block;
    }

    .printable-wrapper {
        transform: scale(0.5);
    }

    .collapse {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .collapse-companion {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5px;
    }

    .mobile-header {
        margin-bottom: 10px;
    }

    #miniModal .modalContent {
        top: 10%;
        left: 1%;
        right: 1%;
        bottom: 10%;
        padding-top: 50px;
        color: #222;
        background: #fff;
    }

    #modal .modalContent {
        top: 1%;
        left: 1%;
        right: 1%;
        bottom: 1%;
        position: absolute;
        z-index: 101;
        padding-top: 50px;
        overflow: auto;
        border-radius: 20px;
        color: #222;
        background: #fff;
    }


    #lockScreen {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 105;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, 0.5);
    }

    #lockScreen .lockContent {
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        z-index: 110;
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
    }
}























/* ANIMATIONS */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated-show {
    display: block;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 10;
}



@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}



@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}


.loadingScreen {
    z-index: 100;
    //background: #dcdcdc;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


//LOADING SCREEN ANIMATION
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #b3b3b3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  //END LOADING SCREEN